import React from "react"
import { motion } from "framer-motion"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import DesktopHeader from "../components/DesktopHeader/DesktopHeader"
import Button from "../components/Button/Button"

import { useWindowSize } from "../utils/useWindowSize"

const ThankYouPage = () => {
  const { width } = useWindowSize()

  const clearStorage = () => {
    localStorage.clear()
  }

  return (
    <Layout>
      <SEO title="Thank You" />
      {width > 1278 ? <DesktopHeader /> : null}
      <motion.div id={"thank-you-container"}>
        <motion.h3 className={"header uppercase"}>
          THANK YOU FOR YOUR ORDER!
        </motion.h3>
        <motion.p className={"paragraph uppercase"}>
          We'll be in contact with information regarding your order.
        </motion.p>
        <motion.p className={"paragraph uppercase"}>
          You can contact us at immaterialcorp@gmail.com if you have any
          additonal questions.
        </motion.p>
        <Button
          text={"BACK TO HOME"}
          to={"https://immaterialcorp.com"}
          className={"thank-you-back-button"}
          onClick={clearStorage}
        />
      </motion.div>
    </Layout>
  )
}

export default ThankYouPage
