import React, { useContext } from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"

import Button from "../Button/Button"
// import AnimatedLogo from "../AnimatedLogo/AnimatedLogo"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import ImmaterialLogo from "../../svg/immaterial-logo.svg"

import "./desktop-header.css"

const DesktopHeader = () => {
  // Global state and actions
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)

  const handleSelectFilter = selectedFilter => {
    dispatch({
      type: "SELECT_FILTER",
      payload: {
        selectedFilter: selectedFilter,
      },
    })
  }

  return (
    <motion.div id={"desktop-header-container"}>
      <motion.div id={"desktop-header-left"}>
        <Link to={"/"}>
          <motion.img
            src={ImmaterialLogo}
            draggable="false"
            layout
            layoutId={"header-logo"}
          ></motion.img>
          {/* <AnimatedLogo /> */}
        </Link>
      </motion.div>
      <motion.div id={"desktop-header-right"}>
        <Link to={"/about"}>
          <motion.div id={"desktop-header-details"}>
            <motion.h1 className={"header uppercase"}>VINTAGE,</motion.h1>
            <motion.h1 className={"header uppercase"}>MUSIC, AND ART</motion.h1>
            <motion.p className={"paragraph uppercase desktop-header-info"}>
              (IM)MATERIAL is a concept store that explores the intangible and
              self-reflective dimensions of physical objects.
            </motion.p>
            <motion.p className={"paragraph uppercase desktop-header-info"}>
              We specialize in salvaging and resurrecting quality and timeless
              vintage pieces.
            </motion.p>
            <motion.p className={"paragraph uppercase desktop-header-info"}>
              Through physical objects and presence, we aspire to establish a
              discourse and build a community that organizes events that
              entertain, educate, and inspire.
            </motion.p>
          </motion.div>
        </Link>
        <motion.div id={"desktop-header-buttons"}>
          <Button
            text={"SHOW ALL"}
            to={"/"}
            onClick={() => handleSelectFilter("")}
            animate={state.selectedFilter === "" ? "selected" : "initial"}
            className={"header-button"}
            initial={state.selectedFilter === "" ? "selected" : "initial"}
          />
          <Button
            text={"TOPS"}
            to={"/"}
            onClick={() => handleSelectFilter("TOPS")}
            animate={state.selectedFilter === "TOPS" ? "selected" : "initial"}
            initial={state.selectedFilter === "TOPS" ? "selected" : "initial"}
            className={"header-button"}
          />
          <Button
            text={"BOTTOMS"}
            to={"/"}
            onClick={() => handleSelectFilter("BOTTOMS")}
            animate={
              state.selectedFilter === "BOTTOMS" ? "selected" : "initial"
            }
            initial={
              state.selectedFilter === "BOTTOMS" ? "selected" : "initial"
            }
            className={"header-button"}
          />
          <Button
            text={"OBJECTS"}
            to={"/"}
            onClick={() => handleSelectFilter("OBJECTS")}
            animate={
              state.selectedFilter === "OBJECTS" ? "selected" : "initial"
            }
            initial={
              state.selectedFilter === "OBJECTS" ? "selected" : "initial"
            }
            className={"header-button"}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export default DesktopHeader
